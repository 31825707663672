import BaseApi from 'actions/api/BaseApi'

const api = new BaseApi().create()

const systemConfigGet = params =>
  api({
    url: '/opi/v1/config',
    method: 'get',
    params,
  })

export default { systemConfigGet }
