import {
  DISMISS_VERIFICATION_CODE_DIALOG,
  SET_VERIFICATION_CODE_DIALOG,
} from 'actions/verificationCode'
import { handleActions } from 'redux-actions'

const initialState = {
  isOpen: false,
  type: null,
  login: null,
  onSuccess: null,
  onCancel: null,
}

export default handleActions(
  {
    [SET_VERIFICATION_CODE_DIALOG]: (state, { payload }) => ({
      ...state,
      ...payload,
      isOpen: true,
    }),
    [DISMISS_VERIFICATION_CODE_DIALOG]: () => initialState,
  },
  initialState,
)
