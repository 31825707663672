import { setSystemError } from 'actions/modal'
import { t } from 'acs-translate'

import { setSystemConfig } from './config'
import createFetchAction from './actionCreator'
import userApi from './api/users'

const {
  userTicketReportList,
  userTicketStateUpdate,
  userTicketExtrainfoUpdate,
  userTicketReportExport,
  userTicketChangeSession,
  userVoucherReportList,
  userVoucherStateUpdate,
  userVoucherReportExport,
  userTicketPdfExport,
  userNftReportList,
  userNftReportExport,
  userNftBlockchainList,
  userAddrsCountries,
  userTicketTransfer,
  userTicketTransferHistory,
  userTicketTransferHistoryReport,
} = userApi

export const getUserTicketReport = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userTicketReportList(data),
    }),
  )

export const updateUserTicketState = (id, data) => async dispatch =>
  dispatch(
    createFetchAction({
      action: userTicketStateUpdate(id, data),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const updateUserExtrainfo = (id, data) => async dispatch =>
  dispatch(
    createFetchAction({
      action: userTicketExtrainfoUpdate(id, data),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const exportUserTicketReport = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userTicketReportExport(data),
    }),
  )

export const updateUserTicketSession = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userTicketChangeSession(data),
      transform: () => ({ success: true }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const getUserVoucherReport = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userVoucherReportList(data),
    }),
  )

export const updateUserVoucherState = (id, data) => async dispatch =>
  dispatch(
    createFetchAction({
      action: userVoucherStateUpdate(id, data),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const exportUserVoucherReport = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userVoucherReportExport(data),
    }),
  )

export const exportUserTicketPdf = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userTicketPdfExport(data),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const getUserNftReport = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userNftReportList(data),
    }),
  )

export const exportUserNftReport = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userNftReportExport(data),
    }),
  )

export const getUserNftBlockchains = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userNftBlockchainList(data),
    }),
  )

export const getUserAddresCountries = data => async (dispatch, getState) =>
  dispatch(
    createFetchAction({
      action: userAddrsCountries(data),
      onComplete: rslt => {
        const state = getState()
        const { countryList = {} } = state.config
        const { platform, type } = data || {}
        const listType = platform ? `${type}-${platform}` : type
        dispatch(
          setSystemConfig({
            countryList: {
              ...countryList,
              [listType]: rslt?.data || [],
            },
          }),
        )
      },
    }),
  )

export const transferUserTicket = (id, data) => async dispatch =>
  dispatch(
    createFetchAction({
      action: userTicketTransfer(id, data),
      transform: () => ({ id }),
      defaultValue: { error: true },
      onError: err => {
        const { code } = err
        let message = `${t(`errYyBo${code}`, {
          fallback: t('errSystemError'),
        })}(${code})`

        if (code === '4003') {
          message = 'errYyBoInvalidTicketState'
        }
        dispatch(
          setSystemError({
            message,
          }),
        )
      },
    }),
  )

export const getUserTransferTicketHistory = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userTicketTransferHistory(data),
    }),
  )

export const exportUserTransferTicketHistory = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userTicketTransferHistoryReport(data),
    }),
  )
