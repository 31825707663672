import { SET_SYSTEM_CONFIG } from 'actions/config'
import { handleActions } from 'redux-actions'

const initialState = {}

export default handleActions(
  {
    [SET_SYSTEM_CONFIG]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
)
