import { createSelector } from 'reselect'

export const userSelector = state => state.user

export const isLoggedInSelector = createSelector(
  userSelector,
  ({ token }) => !!token,
)

export const isActiveUserSelector = createSelector(
  userSelector,
  ({ state }) => state === 'actived',
)

export const userRoleSelector = createSelector(
  userSelector,
  ({ role = 'organizer' }) => role,
)

export const isApprovedUserSelector = createSelector(
  userSelector,
  ({ approvedBy }) => approvedBy > 0,
)

export const userPermissionSelector = createSelector(
  userSelector,
  ({ permissions }) => permissions || [],
)

export const locationSelector = state => state.location

export const verificationCodeSelector = state => state.verificationCode

export const isMultilocationAllowedSelector = createSelector(
  userSelector,
  ({ countries }) => (countries || []).length > 1,
)

export const systemConfigSelector = state => state.config || {}

export const initParamsSelector = state => state.init || {}

export const coutryListSelector = state => state.config?.countryList || {}

export const dialCdListSelector = createSelector(
  coutryListSelector,
  conutryList => {
    const list = conutryList.auth || []
    return list.map(({ dialingCode }) => `+${dialingCode}`)
  },
)

export const otpSecretSelector = state => state.otpSecret || {}
