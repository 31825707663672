import { createAction } from 'redux-actions'

// ------------------------------------
// Constants
// ------------------------------------
export const SET_CONFIRM_DIALOG = '@SET_CONFIRM_DIALOG'
export const SET_SUCCESS_DIALOG = '@SET_SUCCESS_DIALOG'
export const DISMISS_CONFIRM_DIALOG = '@DISMISS_CONFIRM_DIALOG'

// ------------------------------------
// Actions
// ------------------------------------
export const setConfirmDialog = createAction(SET_CONFIRM_DIALOG)
export const setSuccessDialog = createAction(SET_SUCCESS_DIALOG)

export const dismissConfirmDialog = createAction(DISMISS_CONFIRM_DIALOG)
