import { dismissSystemModal } from 'actions/modal'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export default (id, callback) => {
  const dispatch = useDispatch()
  return useCallback(
    () => {
      dispatch(dismissSystemModal({ id }))
      // eslint-disable-next-line no-unused-expressions
      callback?.()
    },
    [id, callback],
  )
}
