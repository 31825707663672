import { createAction } from 'redux-actions'

// ------------------------------------
// Constants
// ------------------------------------
export const SET_INIT_INFO = '@SET_INIT_INFO'
export const CLEAR_INIT_INFO = '@CLEAR_INIT_INFO'

// ------------------------------------
// Actions
// ------------------------------------
export const setInitInfo = createAction(SET_INIT_INFO)
export const clearInitInfo = createAction(CLEAR_INIT_INFO)
