import { dismissConfirmDialog } from 'actions/dialog'
import { t, useLocale } from 'acs-translate'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import React from 'react'
import cx from 'classnames'
import useStyles from 'isomorphic-style-loader/useStyles'

import s from './ConfirmDialog.scss'

const dialogSelector = state => state.dialog

const ConfirmDialog = () => {
  useLocale()
  useStyles(s)
  const dispatch = useDispatch()
  const dialog = useSelector(dialogSelector)
  const {
    isOpen,
    title,
    message,
    contentStyle = {},
    footnote,
    footnoteStyle = {},
    onConfirm,
    onCancel,
    titleColor,
    confirmLabel = 'txtYyBoConfirm',
    cancelLabel = 'txtYyBoCancel',
    type,
    items,
  } = dialog

  const handleClose = () => {
    dispatch(dismissConfirmDialog())
    if (onCancel) onCancel()
  }

  const handleConfirm = () => {
    dispatch(dismissConfirmDialog())
    if (onConfirm) onConfirm()
  }

  return (
    <Dialog open={isOpen && type === 'confirm'} onClose={handleClose}>
      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <ErrorOutlineOutlinedIcon className={s.icon} />
      </Box>
      {!!title && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className={cx(s.title, { [s.normal]: titleColor === 'normal' })}>
            {t(title, { fallback: title })}
          </div>
        </Box>
      )}
      {!!message && (
        <DialogContent className={s.content}>
          <DialogContentText id="alert-dialog-description" style={contentStyle}>
            {t(message, { fallback: message })}
            {!!items &&
              items.map(item => (
                <li style={{ textAlign: 'left' }} key={item.id}>
                  {item.text}
                </li>
              ))}
          </DialogContentText>
        </DialogContent>
      )}
      {!!footnote && (
        <DialogContent className={s.content}>
          <DialogContentText style={footnoteStyle}>
            {t(footnote, { fallback: footnote })}
          </DialogContentText>
        </DialogContent>
      )}
      {isOpen && (
        <div className={s.actions}>
          <Button
            className={s.button}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            disableElevation>
            {t(cancelLabel, { fallback: cancelLabel })}
          </Button>
          <Button
            className={s.button}
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            disableElevation
            autoFocus>
            {t(confirmLabel, { fallback: confirmLabel })}
          </Button>
        </div>
      )}
    </Dialog>
  )
}

export default ConfirmDialog
