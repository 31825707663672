import { LIVE_STREAMING, NFT, TICKETING } from 'constants/path'
import { getSystemConfig } from 'actions/config'
import { getUserAddresCountries } from 'actions/user'
import { logout, userValidate } from 'actions/organizer'
import { setLoadingDone, setLoadingProgress } from 'actions/loading'
import { userPermissionSelector } from 'selectors'
import queryString from 'query-string'

/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'dashboard' */ './dashboard'),
    },
    {
      path: '/dashboard',

      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'dashboard' */ './dashboard'),
        },
        {
          path: '/product',
          load: () => import(/* webpackChunkName: 'dashboard' */ './dashboard'),
        },
        {
          path: '/salesStats',
          load: () =>
            import(/* webpackChunkName: 'dashboard' */ './dashboard/routes/salesStats'),
        },
        {
          path: '/ticketingStats',
          load: () =>
            import(/* webpackChunkName: 'dashboard' */ './dashboard/routes/ticketingSalesStats'),
        },
        {
          path: '/liveStreamingStats',
          load: () =>
            import(/* webpackChunkName: 'dashboard' */ './dashboard/routes/liveStreamingSalesStats'),
        },
      ],
    },
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ './login'),
    },
    {
      path: '/events',
      load: () => import(/* webpackChunkName: 'events' */ './events'),
    },
    {
      path: '/events',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'events' */ './events'),
        },
        {
          path: '/ets',
          load: () =>
            import(/* webpackChunkName: 'events' */ './events/routes/ets'),
        },
        {
          path: `/${TICKETING}`,
          children: [
            {
              path: '',
              load: () =>
                import(/* webpackChunkName: 'events' */ './events/routes/ticketing'),
            },
            {
              path: '/holdTickets',
              load: () =>
                import(/* webpackChunkName: 'events' */ './events/routes/ticketing/routes/holdTickets'),
            },
            {
              path: '/reservedTickets',
              load: () =>
                import(/* webpackChunkName: 'events' */ './events/routes/ticketing/routes/reservedTickets'),
            },
          ],
        },
        {
          path: `/${LIVE_STREAMING}`,
          children: [
            {
              path: '',
              load: () =>
                import(/* webpackChunkName: 'events' */ './events/routes/liveStreaming'),
            },
            {
              path: '/holdLiveStreamingTickets',
              load: () =>
                import(/* webpackChunkName: 'events' */ './events/routes/liveStreaming/routes/holdLiveStreamingTickets'),
            },
            {
              path: '/reservedLiveStreamingTickets',
              load: () =>
                import(/* webpackChunkName: 'events' */ './events/routes/liveStreaming/routes/reservedLiveStreamingTickets'),
            },
          ],
        },
      ],
    },
    {
      path: '/event',
      children: [
        {
          path: '/create',
          load: () =>
            import(/* webpackChunkName: 'events' */ './event/routes/create/index'),
        },
        {
          path: '/ets/create',
          load: () =>
            import(/* webpackChunkName: 'events' */ './event/routes/ets/routes/create/index'),
        },
        {
          path: `/${TICKETING}`,
          children: [
            {
              path: '/create',
              load: () =>
                import(/* webpackChunkName: 'events' */ './event/routes/ticketing/routes/create/index'),
            },
            {
              path: '/holdTicket',
              children: [
                {
                  path: '/create',
                  load: () =>
                    import(/* webpackChunkName: 'events' */ './event/routes/ticketing/routes/holdTicket/routes/index'),
                },
                {
                  path: '/:id',
                  load: () =>
                    import(/* webpackChunkName: 'events' */ './event/routes/ticketing/routes/holdTicket/routes/index'),
                },
              ],
            },
            {
              path: '/sellTicket',
              load: () =>
                import(/* webpackChunkName: 'events' */ './event/routes/ticketing/routes/sellTicket/routes/index'),
            },
          ],
        },
        {
          path: `/${LIVE_STREAMING}`,
          children: [
            {
              path: '/create',
              load: () =>
                import(/* webpackChunkName: 'events' */ './event/routes/create/index'),
            },
            {
              path: '/holdTicket',
              children: [
                {
                  path: '/create',
                  load: () =>
                    import(/* webpackChunkName: 'events' */ './event/routes/ticketing/routes/holdTicket/routes/index'),
                },
                {
                  path: '/:id',
                  load: () =>
                    import(/* webpackChunkName: 'events' */ './event/routes/ticketing/routes/holdTicket/routes/index'),
                },
              ],
            },
            {
              path: '/sellTicket',
              load: () =>
                import(/* webpackChunkName: 'events' */ './event/routes/ticketing/routes/sellTicket/routes/index'),
            },
          ],
        },
        {
          path: '/:id',
          load: () =>
            import(/* webpackChunkName: 'events' */ './event/routes/edit/index'),
        },
      ],
    },
    {
      path: '/home',
      children: [
        {
          path: '/banner',
          children: [
            {
              path: '/create',
              load: () =>
                import(/* webpackChunkName: 'home' */ './home/routes/banner/routes/create/index'),
            },
            {
              path: '/:id',
              load: () =>
                import(/* webpackChunkName: 'home' */ './home/routes/banner/routes/edit/index'),
            },
          ],
        },
        {
          path: '/banners',
          load: () =>
            import(/* webpackChunkName: 'home' */ './home/routes/banners/index'),
        },
        {
          path: '/popupAds',
          children: [
            {
              path: '',
              load: () =>
                import(/* webpackChunkName: 'popupContents' */ './popupAds/index'),
            },
            {
              path: '/settings',
              load: () =>
                import(/* webpackChunkName: 'popupContents' */ './popupAds/routes/settings/index'),
            },
          ],
        },
        {
          path: '/popupAd',
          children: [
            {
              path: '/create',
              load: () =>
                import(/* webpackChunkName: 'popupContents' */ './popupAd/routes/create/index'),
            },
            {
              path: '/:id',
              load: () =>
                import(/* webpackChunkName: 'popupContents' */ './popupAd/routes/edit/index'),
            },
          ],
        },
      ],
    },
    {
      path: '/products',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'products' */ './products'),
        },
        {
          path: `/${NFT}`,
          load: () =>
            import(/* webpackChunkName: 'products' */ './products/routes/nft'),
        },
      ],
    },
    {
      path: '/packages',
      load: () => import(/* webpackChunkName: 'orders' */ './packages'),
    },
    {
      path: '/package',
      children: [
        {
          path: '/create',
          load: () =>
            import(/* webpackChunkName: 'products' */ './package/routes/create/index'),
        },
        {
          path: '/:id',
          load: () =>
            import(/* webpackChunkName: 'products' */ './package/routes/edit/index'),
        },
      ],
    },
    {
      path: '/orders',
      load: () => import(/* webpackChunkName: 'orders' */ './orders'),
    },
    {
      path: '/promotions',
      children: [
        {
          path: '',
          load: () =>
            import(/* webpackChunkName: 'promotions' */ './promotions'),
        },
        {
          path: '/addon',
          load: () =>
            import(/* webpackChunkName: 'promotions' */ './promotions/routes/addon/index'),
        },
      ],
    },
    {
      path: '/order',
      children: [
        {
          path: '/cancelMultiple',
          load: () =>
            import(/* webpackChunkName: 'order' */ './order/routes/cancelMultiple/index'),
        },
        {
          path: '/cancel/:id',
          load: () =>
            import(/* webpackChunkName: 'order' */ './order/routes/cancelMultiple/index'),
        },
        {
          path: '/:id',
          load: () => import(/* webpackChunkName: 'order' */ './order'),
        },
      ],
    },
    {
      path: '/product',
      children: [
        {
          path: '/create',
          load: () =>
            import(/* webpackChunkName: 'products' */ './product/routes/create/index'),
        },
        {
          path: '/:id',
          load: () =>
            import(/* webpackChunkName: 'products' */ './product/routes/edit/index'),
        },
        {
          path: `/${NFT}`,
          children: [
            {
              path: '/create',
              load: () =>
                import(/* webpackChunkName: 'products' */ './product/routes/create/index'),
            },
          ],
        },
      ],
    },
    {
      path: '/members',
      load: () => import(/* webpackChunkName: 'members' */ './members'),
    },
    {
      path: '/member',
      children: [
        {
          path: '/:id',
          load: () => import(/* webpackChunkName: 'staffs' */ './member'),
        },
      ],
    },
    {
      path: '/organizers',
      load: () => import(/* webpackChunkName: 'organizers' */ './organizers'),
    },
    {
      path: '/organizer',
      children: [
        {
          path: '/create',
          load: () =>
            import(/* webpackChunkName: 'organizers' */ './organizer/routes/create/index'),
        },
        {
          path: '/:id',
          // load: () =>
          //   import(/* webpackChunkName: 'organizers' */ './organizer/routes/edit/index'),
          load: () =>
            import(/* webpackChunkName: 'organizers' */ './organizer/routes/detail/index'),
        },
      ],
    },
    {
      path: '/staffs',
      load: () => import(/* webpackChunkName: 'staffs' */ './staffs'),
    },
    {
      path: '/staff',
      children: [
        {
          path: '/create',
          load: () =>
            import(/* webpackChunkName: 'staffs' */ './staff/routes/create/index'),
        },
        {
          path: '/:id',
          load: () =>
            import(/* webpackChunkName: 'staffs' */ './staff/routes/edit/index'),
        },
      ],
    },
    {
      path: '/promotion',
      children: [
        {
          path: '/addon',
          children: [
            {
              path: '/create',
              load: () =>
                import(/* webpackChunkName: 'promotions' */ './promotion/routes/addon/routes/create/index'),
            },
            {
              path: '/:type/:id',
              load: () =>
                import(/* webpackChunkName: 'promotions' */ './promotion/routes/addon/routes/edit/index'),
            },
          ],
        },
        {
          path: '/create',
          load: () =>
            import(/* webpackChunkName: 'promotions' */ './promotion/routes/create/index'),
        },
        {
          path: '/:id',
          load: () =>
            import(/* webpackChunkName: 'promotions' */ './promotion/routes/edit/index'),
        },
      ],
    },
    {
      path: '/register',
      load: () =>
        import(/* webpackChunkName: 'organizers' */ './organizer/routes/register/index'),
    },
    {
      path: '/profile',
      // load: () =>
      //   import(/* webpackChunkName: 'organizers' */ './organizer/routes/edit/index'),
      load: () =>
        import(/* webpackChunkName: 'organizers' */ './organizer/routes/detail/index'),
    },
    {
      path: '/reports',
      children: [
        {
          path: '/tickets',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/tickets/index'),
        },
        {
          path: '/products',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/products/index'),
        },
        {
          path: '/nft',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/nft/index'),
        },
        {
          path: '/productVoucher',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/productVoucher/index'),
        },
        {
          path: '/orderItems',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/orderItems/index'),
        },
        {
          path: '/eventPb',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/eventPb/index'),
        },
        // {
        //   path: '/memberAddress',
        //   load: () =>
        //     import(/* webpackChunkName: 'reports' */ './reports/routes/memberAddress/index'),
        // },
        {
          path: '/organizers',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/organizers/index'),
        },
        {
          path: '/monthendPaymentSummary',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/monthendPaymentSummary/index'),
        },
        {
          path: '/monthendPaymentDetail',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/monthendPaymentDetail/index'),
        },
        {
          path: '/eventSeat',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/eventSeat/index'),
        },
        {
          path: '/eventAdmission',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/eventAdmission/index'),
        },
        {
          path: '/ticketingGainSharingStat',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/ticketingGainSharingStat/index'),
        },
        {
          path: '/concertOrLiveStreamingApplications',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/concertOrLiveStreamingApplications/index'),
        },
        {
          path: '/packages',
          load: () =>
            import(/* webpackChunkName: 'reports' */ './reports/routes/packages/index'),
        },
      ],
    },
    {
      path: '/systems',
      children: [
        {
          path: '/eventCategories',
          load: () =>
            import(/* webpackChunkName: 'systems' */ './systems/routes/eventCategories/index'),
        },
        {
          path: '/productCategories',
          load: () =>
            import(/* webpackChunkName: 'systems' */ './systems/routes/productCategories/index'),
        },
        {
          path: '/eventTopics',
          load: () =>
            import(/* webpackChunkName: 'systems' */ './systems/routes/topics/index'),
        },
        {
          path: '/productTopics',
          load: () =>
            import(/* webpackChunkName: 'systems' */ './systems/routes/topics/index'),
        },
        {
          path: '/venues',
          load: () =>
            import(/* webpackChunkName: 'systems' */ './systems/routes/venues/index'),
        },
        {
          path: '/venue',
          children: [
            {
              path: '/create',
              load: () =>
                import(/* webpackChunkName: 'systems' */ './systems/routes/venue/routes/create/index'),
            },
            {
              path: '/:venueId',
              children: [
                {
                  path: '',
                  load: () =>
                    import(/* webpackChunkName: 'systems' */ './systems/routes/venue/routes/edit/index'),
                },
                {
                  path: '/seatMap',
                  children: [
                    {
                      path: '/create',
                      load: () =>
                        import(/* webpackChunkName: 'systems' */ './systems/routes/venue/routes/edit/routes/seatMap/routes/create/index'),
                    },
                    {
                      path: '/:seatMapId',
                      load: () =>
                        import(/* webpackChunkName: 'systems' */ './systems/routes/venue/routes/edit/routes/seatMap/routes/edit/index'),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/stats',
      load: () =>
        import(/* webpackChunkName: 'systems' */ './systems/routes/stats/index'),
    },
    {
      path: '/svgDemo',
      load: () => import(/* webpackChunkName: 'test' */ './test'),
    },
    {
      path: '/concertOrg',
      children: [
        {
          path: '/application',
          load: () =>
            import(/* webpackChunkName: 'concertOrg' */ './concertOrg/routes/application/index'),
        },
      ],
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async config(context) {
    const { store, query } = context
    const { dispatch } = store
    const { token: extToken, ...restQuery } = query
    const restQueryString = queryString.stringify(restQuery)

    if (extToken) {
      window.history.replaceState(null, null, `?${restQueryString}`)
    }

    await dispatch(getSystemConfig())
    await dispatch(userValidate({ extToken }))
    await dispatch(getUserAddresCountries({ type: 'auth' }))
  },

  async action(context) {
    // Execute each child route until one of them return the result
    const { next, route: contextRoute } = context

    if (contextRoute.config) {
      await contextRoute.config(context)
      // https://juejin.im/post/5d59fd7ff265da03f233cc4b
      context.route.config = undefined // eslint-disable-line no-param-reassign
    }

    const {
      store: { dispatch, getState },
    } = context
    dispatch(setLoadingProgress())
    const route = await next()
    dispatch(setLoadingDone())
    if (route?.permissions) {
      const userPermissions = userPermissionSelector(getState())
      const hasPermission = route.permissions.every(permission =>
        userPermissions.includes(permission),
      )
      if (!hasPermission) dispatch(logout())
    }

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} - Bookyay Organizer`
    route.description = route.description || ''

    return route
  },
}

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  })
}

export default routes
