/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['inputModal', 'loadingModal', 'actionModal', 'actionGroup', 'actionButton', 'dialogInput'] }] */
import React, { PureComponent } from 'react'
import { Translate } from 'acs-translate'
import { throttle } from 'throttle-debounce'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'

import classes from '../Modal.Basic.scss'

class DialogModal extends PureComponent {
  static closeTimeoutMS = 300

  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.node,
    message: PropTypes.node.isRequired,
    shouldCloseOnOverlayClick: PropTypes.bool,
    hideBtns: PropTypes.bool,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node.isRequired,
        onClick: PropTypes.func,
      }),
    ),
    onAfterOpen: PropTypes.func,
    onRequestClose: PropTypes.func.isRequired,
  }

  constructor() {
    super()

    // throttle execution of click function to prevent fire multiple times
    this.handleRequestClose = throttle(
      DialogModal.closeTimeoutMS,
      this.handleRequestClose,
      true,
    )
  }

  onClick = ({ target: el }) => {
    const index = [...el.parentElement.children].indexOf(el)
    const { onClick } = this.props.buttons[index]
    if (onClick) {
      onClick(this.handleRequestClose, index)
    } else {
      this.handleRequestClose()
    }
  }

  get isAllowedBackdrop() {
    const { shouldCloseOnOverlayClick, buttons } = this.props
    if (typeof shouldCloseOnOverlayClick === 'undefined') {
      return typeof buttons === 'undefined'
    }
    return shouldCloseOnOverlayClick
  }

  /**
   * Bug fix
   * @see https://github.com/reactjs/react-modal/issues/99
   */
  handleAfterOpenModal = () => {
    const { onAfterOpen } = this.props
    document.body.classList.add('ReactModal__Body--open')
    if (onAfterOpen) {
      onAfterOpen.bind(this)
    }
  }

  handleRequestClose = () => {
    const { onRequestClose } = this.props
    onRequestClose()
  }

  render() {
    const {
      className,
      title,
      message,
      buttons,
      hideBtns,
      ...props
    } = this.props
    return (
      <Modal
        {...props}
        className={classnames(classes.dialogModal, className, {
          [classes.hideBtns]: hideBtns,
        })}
        overlayClassName={classes.overlay}
        shouldCloseOnOverlayClick={this.isAllowedBackdrop}
        closeTimeoutMS={DialogModal.closeTimeoutMS}
        onAfterOpen={this.handleAfterOpenModal}
        onRequestClose={this.handleRequestClose}>
        <div className={classes.dialogInner}>
          {!!title && <div className={classes.dialogTitle}>{title}</div>}
          <div className={classes.dialogText}>{message}</div>
        </div>
        <div className={classes.dialogButtons}>
          {buttons &&
            buttons.map(({ label }, index) => (
              <div
                role="presentation"
                // eslint-disable-next-line react/no-array-index-key
                key={`dialog-modal-button-${index}`}
                className={classes.dialogButton}
                onClick={this.onClick}>
                {label}
              </div>
            ))}
          {!buttons && (
            <Translate
              component="div"
              className={classes.dialogButton}
              content="txtConfirm"
              onClick={this.handleRequestClose}
            />
          )}
        </div>
      </Modal>
    )
  }
}

DialogModal.defaultProps = {
  className: '',
  title: '',
  shouldCloseOnOverlayClick: true,
  hideBtns: false,
  buttons: null,
  onAfterOpen: null,
}

export default withStyles(classes)(DialogModal)
