import { USER_PERMISSIONS } from 'constants/user'

export default permissions => {
  if (permissions.includes(USER_PERMISSIONS.DASHBOARD_EVENT_SALES)) {
    return '/dashboard'
  }

  if (permissions.includes(USER_PERMISSIONS.DASHBOARD_TICKETING_SALES)) {
    return '/dashboard/ticketingStats'
  }

  if (permissions.includes(USER_PERMISSIONS.ORDER_REPORT)) {
    return '/reports/orderItems'
  }

  if (permissions.includes(USER_PERMISSIONS.EVENT_TICKET_REPORT)) {
    return '/reports/tickets'
  }

  return '/'
}
