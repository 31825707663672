import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import Logger from 'utils/Logger'
import PropTypes from 'prop-types'
import React from 'react'
import SuccessDialog from 'components/SuccessDialog/SuccessDialog'

import { ErrorDialog } from './Dialog'

const types = {
  success: 'success',
  error: 'error',
  confirm: 'confirm',
}

const Modal = ({ type, ...props }) => {
  switch (type) {
    case types.error:
      return <ErrorDialog {...props} />
    case types.confirm:
      return <ConfirmDialog {...props} />
    case types.success:
      return <SuccessDialog {...props} />
    default:
      Logger.error(`Incorrect Modal type ${type}`)
  }
  return null
}

Modal.types = types

Modal.propTypes = {
  type: PropTypes.oneOf([types.success, types.error]).isRequired,
}

export default Modal
