import { ERR_LOGIN_INCORRECT_PWD } from 'constants/error'
import {
  MEMBER_ROLES,
  USER_PERMISSIONS,
  withReadOnlyPermission,
  withReadPermission,
} from 'constants/user'
import { createAction } from 'redux-actions'
import { deleteCookie, getBookyayCc } from 'utils/cookies'
import { setChangeLocationDialog, updateLocation } from 'actions/location'
import { setSuccessDialog } from 'actions/dialog'
import { setTncModal } from 'actions/tncModal'
// import { setSuccessMessage } from 'actions/modal'
import { t } from 'acs-translate'
import { userSelector } from 'selectors'
import getHomeRoute from 'utils/getHomeRoute'
import getIsHK from 'utils/getIsHK'
import locations from 'constants/locations'
import store from 'store'

import { enqueueSnackbar } from './notifications'
import createFetchAction from './actionCreator'
import organizerApi from './api/organizer'

// import Logger from 'utils/Logger'

const IS_NEED_CHECK_SHOW_TNC = false

const {
  login: auth,
  validate,
  organizerList,
  organizerGet,
  organizerCreate,
  organizerUpdate,
  organizerDelete,
  organizerRegister,
  otpCreate,
  organizerStatusUpdate,
  organizerIdentityUpdate,
  organizerIdentityUpdateConfirm,
  organizerRevisions,
  organizerUpdatePassword,
  organizerResetPassword,
  organizerStaff,
  organizerBlock,
  organizerReportExport,
  organizerStores,
  organizerStoreCreate,
  organizerStoreUpdate,
  organizerStoreDelete,
  organizerBankInfoUpdate,
  organizerBanks,
  organizerBankCreate,
  organizerBankUpdate,
  organizerBanksExport,
  organizerAutosave,
  organizersDocIdentity,
  organizersDocIdentityUpload,
  organizersBankAuthLetter,
  organizersBankAuthLetterUpload,
  organizerTnc,
  organizerConcertTnc,
  organizerTncTemplate,
  organizerTncAgree,
  organizerConcertApplicationsCreate,
  organizerConcertApplicationsList,
  organizerConcertApplicationStatusUpdate,
  organizerConcertApplicationsExport,
  organizerCountryCodes,
} = organizerApi

export const sessionCreated = createAction('SESSION_CREATED')
export const sessionRemoved = createAction('SESSION_REMOVED')
export const updateUserProfile = createAction('UPDATE_USER_PROFILE')
export const updateSessionToken = createAction('UPDATE_SESSION_TOKEN')

export const requestOtp = data => async dispatch =>
  dispatch(
    createFetchAction({
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
      shouldThrowError: true,
      action: otpCreate(data),
    }),
  )

const getPermissions = ({ permissions }) => {
  // if (countryCode === 'TW') return permissions
  const hasDashboard = permissions.includes('dashboard')
  const hasSystemSettings = permissions.includes('systemSettings')
  const hasEvents = permissions.includes(USER_PERMISSIONS.EVENTS)
  const hasTicketingEvents = permissions.includes(
    USER_PERMISSIONS.TICKET_EVENTS,
  )
  const hasTicketingEventsReadOnly = permissions.includes(
    withReadOnlyPermission(USER_PERMISSIONS.TICKET_EVENTS),
  )
  const hasLookyay = permissions.includes(USER_PERMISSIONS.LOOKYAY)
  const hasLookyayReadOnly = permissions.includes(
    withReadOnlyPermission(USER_PERMISSIONS.LOOKYAY),
  )

  return [
    ...permissions,
    ...(hasDashboard ? ['dashboard-product'] : []),
    ...(((hasTicketingEvents && !hasTicketingEventsReadOnly) ||
      (hasLookyay && !hasLookyayReadOnly)) &&
    hasDashboard
      ? [USER_PERMISSIONS.DASHBOARD_VIEW_ALL]
      : []),
    ...((hasTicketingEvents && !hasTicketingEventsReadOnly) ||
    (hasLookyay && !hasLookyayReadOnly && hasDashboard)
      ? [USER_PERMISSIONS.DASHBOARD_PRODUCT_VIEW_ALL]
      : []),
    ...(hasSystemSettings ? ['systemSettings-product-cat'] : []),
    ...(hasEvents ||
    hasTicketingEvents ||
    hasTicketingEventsReadOnly ||
    hasLookyay ||
    hasLookyayReadOnly
      ? [withReadPermission(USER_PERMISSIONS.EVENTS)]
      : []),
    ...(hasTicketingEvents || hasTicketingEventsReadOnly
      ? [withReadPermission(USER_PERMISSIONS.TICKET_EVENTS)]
      : []),
    ...(hasLookyay || hasLookyayReadOnly
      ? [withReadPermission(USER_PERMISSIONS.LOOKYAY)]
      : []),
    ...(hasTicketingEvents ||
    hasTicketingEventsReadOnly ||
    hasLookyay ||
    hasLookyayReadOnly
      ? [withReadPermission(USER_PERMISSIONS.TICKET_EVENTS_HOLD_TICKETS)]
      : []),
  ]
}

export const userValidate = ({
  extToken,
  countryCode,
  callback,
} = {}) => async (dispatch, _, { history }) => {
  const prevToken = extToken || store.get('token')

  if (!prevToken) {
    return null
  }

  return dispatch(
    createFetchAction({
      action: validate(prevToken, countryCode),
      useCustomErrorMessage: false,
      transform: ({
        organizer,
        permissions,
        token,
        countries,
        countryCode: rCountryCode,
      }) => ({
        countryCode: rCountryCode,
        user: {
          ...organizer,
          countries,
          token,
          permissions: getPermissions({
            permissions,
            countryCode: rCountryCode,
          }),
        },
      }),
      onError: () => {
        store.remove('token')
      },
      onComplete: rslt => {
        dispatch(sessionCreated(rslt.user))
        store.set('token', rslt.user.token)
        const cLocation = countryCode || rslt.countryCode || getBookyayCc()
        const location = locations.find(l => l.value === cLocation)
        if (location) {
          const { value, currencyCode } = location
          dispatch(updateLocation({ countryCode: value, currencyCode }))
        }
        if (callback) {
          callback()
          return
        }
        const { state, approvedBy } = rslt.user
        if (state !== 'actived' && (extToken || approvedBy === '0')) {
          history.push('/profile')
        }
      },
    }),
  )
}

const setLoginUser = ({ user, countryCode }) => (dispatch, _, { history }) => {
  dispatch(sessionCreated(user))
  store.set('token', user.token)
  if (user.countries && user.countries?.length === 1) {
    const homeRoute = getHomeRoute(user.permissions || [])
    const [location] = user.countries
    const { currencyCode } = location
    dispatch(updateLocation({ countryCode, currencyCode }))
    const { state } = user
    history.push(state === 'actived' ? homeRoute : '/profile')
    return true
  }
  return false
}

export const afterLogin = ({ actionIsRegister, rslt, dispatch, history }) => {
  if (rslt.isRegister && actionIsRegister) {
    dispatch(sessionCreated(rslt.user))
    history.push(`/register`, {
      isInternal: true,
    })
    return
  }
  const { user, countryCode } = rslt
  const homeRoute = getHomeRoute(user.permissions || [])
  if (homeRoute === '/') {
    dispatch(
      enqueueSnackbar({
        message: t('errYyBoLoginUnauthorized'),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    )
    return
  }
  const completed = dispatch(setLoginUser({ user, countryCode }))
  if (completed) return
  dispatch(
    setChangeLocationDialog({
      allowedLocation: user.countries,
      onConfirm: item => {
        const { value } = item
        const callback = () => {
          const { state } = user
          history.push(state === 'actived' ? homeRoute : '/profile')
        }
        dispatch(userValidate({ countryCode: value, callback }))
      },
    }),
  )
}

export const login = ({ isRegister: actionIsRegister, data }) => async (
  dispatch,
  getState,
  { history },
) =>
  dispatch(
    createFetchAction({
      action: auth(data),
      transform: ({
        token,
        organizer,
        permissions,
        isRegister,
        countries,
        countryCode,
      }) => ({
        isRegister,
        countryCode,
        user: {
          ...organizer,
          token,
          permissions: getPermissions({
            permissions,
            countryCode,
          }),
          countries,
        },
      }),
      shouldThrowError: true,
      useCustomErrorMessage: data?.via === 'pwd',
      defaultErrorMessageCd: 'errYyBo',
      onComplete: rslt => {
        const { user, countryCode, isRegister } = rslt
        if (
          IS_NEED_CHECK_SHOW_TNC &&
          user.role === MEMBER_ROLES.ORGANIZER &&
          user?.isNormalOrganizer &&
          !user?.normalTermsAndConditionsAgreed &&
          !isRegister &&
          getIsHK(countryCode)
        ) {
          dispatch(
            setTncModal({
              organizerId: user.id,
              token: user.token,
              canCancel: false,
              onSuccess: () => {
                dispatch(setLoginUser({ user, countryCode }))
              },
            }),
          )
          return
        }
        // Register flow for HK org
        if (actionIsRegister && getIsHK(countryCode)) {
          dispatch(
            setSuccessDialog({
              title: 'txtYyBoCreateAcctSuccess',
              message: 'txtYyBoCreateAcctSuccessInfo',
              onConfirm: () => {
                dispatch(setLoginUser({ user, countryCode }))
              },
              isShowCancel: false,
              canCancel: false,
            }),
          )
          return
        }

        afterLogin({ actionIsRegister, rslt, dispatch, history })
      },
    }),
  )

export const updateUserCountry = () => (dispatch, getState, { history }) => {
  const user = userSelector(getState())
  return dispatch(
    setChangeLocationDialog({
      allowBackdrop: true,
      allowedLocation: user?.countries,
      onConfirm: item => {
        const { value: countryCode } = item
        const callback = () => {
          const homeRoute = getHomeRoute(user.permissions || [])
          const { state } = user
          history.push(state === 'actived' ? homeRoute : '/profile')
        }
        dispatch(userValidate({ countryCode, callback }))
      },
    }),
  )
}

export const logout = () => async (dispatch, _, { history }) => {
  dispatch(sessionRemoved())
  store.remove('token')
  history.push('/login')
  deleteCookie('bookyay_cc')
}

export const getOrganizers = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerList(data),
    }),
  )

export const getOrganizer = id => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerGet(id),
    }),
  )

export const createOrganzier = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerCreate(data),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const updateOrganzier = (id, data, token) => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerUpdate(id, data, token),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const deleteOrganzier = id => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerDelete(id),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const registerOrganizer = data => async dispatch => {
  const rslt = await dispatch(
    createFetchAction({
      action: organizerRegister(data),
      // transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )
  if (!rslt) return {}
  const { countryCode, token } = rslt
  const location = locations.find(l => l.value === countryCode)
  if (location) {
    const { value, currencyCode } = location
    dispatch(updateLocation({ countryCode: value, currencyCode }))
  }
  dispatch(updateSessionToken(token))
  // to match response of updateOrganzier
  return { id: '1' }
}

export const updateOrganizerStatus = (
  id,
  data,
  { onError } = {},
) => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerStatusUpdate(id, data),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
      onError,
    }),
  )

export const updateOrganzierIdentity = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerIdentityUpdate(data),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const confirmUpdateOrganzierIdentity = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerIdentityUpdateConfirm(data),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: data?.hideError ? '' : 'errYyBo',
    }),
  )

export const getOrganizerRevisions = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerRevisions({ pageNo: 1, pageSize: 1, ...data }),
    }),
  )

export const updateOrganizerPassword = (data, context) => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerUpdatePassword(data),
      transform: () => ({ success: true }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
      specialHandlingErrors: [
        {
          code: ERR_LOGIN_INCORRECT_PWD,
          onError: error => {
            if (error.code === ERR_LOGIN_INCORRECT_PWD) {
              context.setError(t('errYyBoOldPasswordNotCorrect'))
            }
          },
        },
      ],
    }),
  )

export const resetOrganizerPassword = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerResetPassword(data),
      transform: () => ({ success: true }),
      // useCustomErrorMessage: true,
      // defaultErrorMessageCd: 'errYyBo',
      onComplete: () => {
        dispatch(
          setSuccessDialog({
            title: 'txtYyBoResetPasswordSuccess',
            message: 'txtYyBoResetPasswordSuccess',
            isShowCancel: false,
          }),
        )
      },
    }),
  )

export const getOrganizerStaff = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerStaff(data),
    }),
  )

export const updateOrganizerBlockStatus = (id, data) => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerBlock(id, data),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const exportOrganizerReport = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerReportExport(data),
    }),
  )

export const getOrganizerStores = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerStores(data),
    }),
  )

export const createOrganzierStore = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerStoreCreate(data),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const updateOrganzierStore = (id, data) => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerStoreUpdate(id, data),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const deleteOrganzierStore = id => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerStoreDelete(id),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const updateOrganzierBankInfo = (id, data) => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerBankInfoUpdate(id, data),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const getOrganizerBanks = params => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerBanks(params),
    }),
  )

export const createOrganzierBank = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerBankCreate(data),
      useCustomErrorMessage: true,
      transform: () => ({ success: true }),
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const updateOrganzierBank = (id, data) => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerBankUpdate(id, data),
      transform: () => ({ success: true }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const exportOrganizerBanksReport = params => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerBanksExport(params),
      transform: () => ({ success: true }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const autoSaveOrganizerInfo = (data, loadingTarget) => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerAutosave(data, loadingTarget),
      transform: () => ({ success: true }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const getOrganizerIdentityDoc = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizersDocIdentity(data),
    }),
  )

export const uploadOrganizerIdentityDoc = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizersDocIdentityUpload(data),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const getOrganizerBankAuthLetter = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizersBankAuthLetter(data),
    }),
  )

export const uploadOrganizerBankAuthLetter = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizersBankAuthLetterUpload(data),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const getOrganizerTncById = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerTnc(data),
    }),
  )

export const getOrganizerTncTemplate = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerTncTemplate(data),
      transform: content => ({ content }),
    }),
  )

export const updateOrganizerTncAgree = (data, token) => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerTncAgree(data, token),
      transform: () => ({ success: true }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const getOrganizerConcertTncById = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerConcertTnc(data),
    }),
  )

export const createOrganizerConcertApplications = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerConcertApplicationsCreate(data),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const getOrganizerConcertApplications = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerConcertApplicationsList(data),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const updateOrganizerConcertApplicationStatus = (
  id,
  data,
) => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerConcertApplicationStatusUpdate(id, data),
      transform: () => ({ id }),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )

export const exportOrganizerConcertApplicationsReport = params => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerConcertApplicationsExport(params),
    }),
  )

export const getOrganizerCountryCodes = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: organizerCountryCodes(data),
      useCustomErrorMessage: true,
      defaultErrorMessageCd: 'errYyBo',
    }),
  )
