import { createAction } from 'redux-actions'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

export const removeSnackbar = createAction(REMOVE_SNACKBAR)

export const closeSnackbar = createAction(CLOSE_SNACKBAR)

export const enqueueSnackbar = notification => (dispatch, getState) => {
  const key = notification.options && notification.options.key
  const { preventDuplicate = false, ...rest } = notification
  if (preventDuplicate) {
    const { notifications = [] } = getState()
    const duplicated = notifications.find(
      noti => noti.message === notification.message,
    )
    if (duplicated) return null
  }
  return dispatch(
    createAction(ENQUEUE_SNACKBAR)({
      ...rest,
      options: {
        ...notification?.options,
        action: actionKey => (
          <IconButton onClick={() => dispatch(closeSnackbar(actionKey))}>
            <CloseOutlinedIcon />
          </IconButton>
        ),
      },
      key: key || new Date().getTime() + Math.random(),
    }),
  )
}
