import { DISMISS_TNC_MODAL, SET_TNC_MODAL } from 'actions/tncModal'
import { handleActions } from 'redux-actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { isOpen: false }

export default handleActions(
  {
    [SET_TNC_MODAL]: (_, { payload }) => ({ ...payload, isOpen: true }),
    [DISMISS_TNC_MODAL]: () => ({ isOpen: false }),
  },
  initialState,
)
