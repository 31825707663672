import { handleActions } from 'redux-actions'
import { setOtpSecret } from 'actions/otpSecret'

const initialState = {
  otpSecret: '',
  otpIv: '',
}

export default handleActions(
  {
    [setOtpSecret]: (_, { payload }) => payload,
  },
  initialState,
)
