exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2f4zG{color:#e81e62;font-weight:400;font-size:20px;margin:24px 0}._2f4zG._2xgMw{color:rgba(0,0,0,.87)}._5dGuU{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;min-width:512px;max-width:100vw;white-space:pre-line;text-align:center}.JRXhy{min-width:120px;-ms-flex:1 1 0%;flex:1 1 0%;margin:0 8px}._3_m6W{margin:24px 0;padding:0 24px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._3GQYn{color:#1fcd00;font-size:60px}", ""]);

// exports
exports.locals = {
	"title": "_2f4zG",
	"normal": "_2xgMw",
	"content": "_5dGuU",
	"button": "JRXhy",
	"actions": "_3_m6W",
	"icon": "_3GQYn"
};