import { handleActions } from 'redux-actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default handleActions(
  {
    SESSION_CREATED: (state, { payload }) => payload,
    SESSION_REMOVED: () => ({}),
    UPDATE_USER_PROFILE: (state, { payload }) => {
      const { id, login } = payload
      if (state.id !== id) return state
      // only update login at the moment
      return { ...state, login }
    },
    UPDATE_SESSION_TOKEN: (state, { payload }) => ({
      ...state,
      token: payload,
    }),
  },
  initialState,
)
