export const listCookies = () =>
  document.cookie.split(';').map(c => {
    const cs = c.trim().split('=')
    return { name: cs[0], value: c.replace(`${cs[0]}=`, '').trim() }
  })

export const getBookyayCc = () => {
  const cookies = listCookies() || []
  const bookyayCc = cookies.find(c => c.name === 'bookyay_cc')
  return bookyayCc?.value || ''
}

export const deleteCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}
