export const MEMBER_ROLES = {
  BOOKYAY_ADMIN: 'bookyay_admin',
  BOOKYAY_SUPER_ADMIN: 'bookyay_super_admin',
  ORGANIZER: 'organizer',
  BOOKYAY_ACCOUNTANT: 'bookyay_accountant',
  ORGANIZER_EXECUTIVE: 'organizer_executive',
  ORGANIZER_STAFF: 'organizer_staff',
  ORGRANIZER_ACCOUNTANT: 'organizer_accountant',
}

export const MEMBER_ROLES_MAP = {
  bookyay_admin: 'txtYyBoMemberRoleAdmin',
  bookyay_super_admin: 'txtYyBoMemberRoleSuperAdmin',
  organizer: 'txtYyBoMemberRoleOrganizer',
  bookyay_accountant: 'txtYyBoMemberRoleAccountant',
  organizer_executive: 'txtYyBoMemberRoleExecutive',
  organizer_accountant: 'txtYyBoMemberRoleAccountant',
  organizer_staff: 'txtYyBoMemberRoleStaff',
}

export const ADMIN_ROLES_OPTIONS = [
  MEMBER_ROLES.BOOKYAY_ADMIN,
  MEMBER_ROLES.BOOKYAY_ACCOUNTANT,
  MEMBER_ROLES.ORGRANIZER_ACCOUNTANT,
  MEMBER_ROLES.ORGANIZER_EXECUTIVE,
  MEMBER_ROLES.ORGANIZER_STAFF,
].map(key => ({
  value: key,
  label: MEMBER_ROLES_MAP[key],
  group: key.split('_')[0] === 'organizer' ? 'txtYyBoOrganizer' : 'Bookyay',
}))

export const ORGANIZER_ROLES_OPTIONS = Object.keys(MEMBER_ROLES_MAP)
  .filter(key => key.startsWith('organizer_'))
  .map(key => ({
    value: key,
    label: MEMBER_ROLES_MAP[key],
  }))

export const READ_ONLY_POSTFIX = 'ReadOnly'

export const READ_POSTFIX = 'Read'

export const withReadOnlyPermission = permission =>
  `${permission}${READ_ONLY_POSTFIX}`

export const withReadPermission = permission => `${permission}${READ_POSTFIX}`

export const USER_PERMISSIONS = {
  DASHBOARD: 'dashboard',
  DASHBOARD_MULTI_LOCATION: 'dashboardMultiLocation',
  DASHBOARD_VIEW_ALL: 'dashboardViewAll',
  DASHBOARD_PRODUCT_VIEW_ALL: 'dashboardProductViewAll',
  DASHBOARD_PRODUCT: 'dashboard-product',
  DASHBOARD_EVENT_SALES: 'dashboardEventSales',
  DASHBOARD_PRODUCT_SALES: 'dashboardProductSales',
  DASHBOARD_TICKETING_SALES: 'dashboardTicketingSales',
  DASHBOARD_LOOKYAY_SALES: 'dashboardLookyaySales',
  EVENTS: 'events',
  ETS_EVENTS: 'etsEvents',
  TICKET_EVENTS: 'ticketingEvents',
  TICKET_EVENTS_HOLD_TICKETS: 'ticketingHoldTickets',
  PRODUCTS: 'products',
  PRODUCTS_NFT: 'productNFT',
  PACKAGES: 'packages',
  ORDERS: 'orders',
  PROMOTIONS: 'promotions',
  REDEEM_EVENT_TICKET: 'redeem_events_ticket',
  USER_MANAGEMENT: 'userManagement',
  ORGANIZERS: 'organizers',
  STAFF: 'staff',
  ORDER_REPORT: 'orderReport',
  ORGANIZER_REPORT: 'organizerReport',
  EVENT_TICKET_REPORT: 'eventTicketReport',
  PRODUCT_SALE_REPORT: 'productSaleReport',
  MONTHEND_PAYMENT_REPORT: 'monthendPaymentReport',
  EVENT_TICKET_REDEEM_REPORT: 'eventTicketRedeemReport',
  PRODUCT_VOUCHER_REPORT: 'productVoucherReport',
  PRODUCT_VOUCHER_REDEEM_REPORT: 'productVoucherRedeemReport',
  SELF_INFO: 'selfInfo',
  SELF_MESSAGE: 'selfMessage',
  SELF_PASSWORD: 'selfPassword',
  SYSTEM_SETTINGS: 'systemSettings',
  HOME_BANNERS: 'homeBanners',
  TICKETING_TEMPLATES: 'ticketingTemplates',
  TICKETING_EVENT_SEAT_STAT: 'ticketingEventSeatStat',
  TICKETING_GAIN_SHARING_STAT: 'ticketingEventGainsharingStat',
  LOOKYAY: 'lookyay',
  CONCERT_APPLICATIONS: 'concertApplications',
  CHANGE_TICKET_STATE: 'changeTicketState',
  EVENT_TICKET_PRICE: 'eventTicketPrice',
  CHANGE_PRODUCT_STATE: 'changeProductState',
  EVENT_TICKET_TRANSFER_REPORT: 'eventTicketTransferReport',
  // client side permission
  PRODUCT_CATE_SETTING: 'systemSettings-product-cat',
  POPUP_ADS: 'popupAds',
}
