exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2OcFM{font-size:80px;color:#d00}._38vEJ{font-size:20px;font-weight:600}._6G28x{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;min-width:512px;max-width:100vw;text-align:center;white-space:pre-line}.jjEf2{min-width:120px;-ms-flex:1 1 0%;flex:1 1 0%;margin:0 8px}._1VvAS{margin:24px 0;padding:0 24px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}", ""]);

// exports
exports.locals = {
	"icon": "_2OcFM",
	"title": "_38vEJ",
	"content": "_6G28x",
	"button": "jjEf2",
	"actions": "_1VvAS"
};