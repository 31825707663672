import 'core-js/stable'
import 'core-js/modules/es.object.assign'
import 'regenerator-runtime/runtime'
import Locale, { EnhanceTranslate } from 'acs-translate'
import { Provider as ReduxProvider } from 'react-redux'
import { createPath } from 'history/PathUtils'
import FastClick from 'fastclick'
import HistoryContext from 'context/history'
import Modernizr from 'modernizr'
import React from 'react'
import ReactDOM from 'react-dom'
import StyleContext from 'isomorphic-style-loader/StyleContext'
import deepForceUpdate from 'react-deep-force-update'
import queryString from 'query-string'

import { injectReducer } from './store/reducers'
import { updateMeta } from './utils/DOMUtils'
import App from './components/App'
import Logger from './utils/Logger'
import configureStore from './store/configureStore'
import history from './history'
import router from './router'

console.info('__DEV__:', __DEV__)
console.info('__LAB__:', __LAB__)
console.info('__UAT__:', __UAT__)
console.info('__PROD__:', __PROD__)
console.info('__VERSION__:', __VERSION__)
console.info('Modernizr:', Modernizr)

// Logger Setup
Logger.setup()

// Version Setup
window.version = __VERSION__

// FastClick Setup
FastClick.attach(document.body)

// Translation Setup
const localeSetup = async () =>
  Locale.setup({
    debug: false,
    defaultLocale: 'zh-TW',
    fallbackLocale: 'zh-TW',
    locales: ['zh-TW', 'en-US'],
    loadResourceCallback: async langCd => {
      try {
        const data = await import(/* webpackChunkName: 'locales' */ `locales/${langCd}.json`)
        return data
      } catch (error) {
        Logger.error(`[Locale] load ${langCd}.json fail`)
      }
      return {}
    },
  })
EnhanceTranslate.projectCd = 'YyBo'

// Enables critical path CSS rendering
// https://github.com/kriasoft/isomorphic-style-loader
const insertCss = (...styles) => {
  // eslint-disable-next-line no-underscore-dangle
  const removeCss = styles.map(x => x._insertCss())
  return () => {
    removeCss.forEach(f => f())
  }
}

// Initialize a new Redux store
// http://redux.js.org/docs/basics/UsageWithReact.html
const store = configureStore({}, { history })

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  store,
  // Navigation manager, e.g. history.push('/home')
  // https://github.com/mjackson/history
  history,
  // Inject reducer arbitrarily rather than top level for redux store to replace reducer
  // https://github.com/davezuko/react-redux-starter-kit/blob/master/src/store/reducers.js
  // https://medium.com/@jimmy_shen/inject-reducer-arbitrarily-rather-than-top-level-for-redux-store-to-replace-reducer-fdc1060a6a7
  injectReducer,
}

const container = document.getElementById('app')
let currentLocation = history.location
let appInstance

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {}
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual'
}

// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  }
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key]
  }
  currentLocation = location

  const isInitialRender = !action
  try {
    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    if (isInitialRender) {
      await localeSetup()
    }

    const route = await router.resolve({
      ...context,
      pathname: location.pathname,
      query: queryString.parse(location.search),
    })

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return
    }

    if (route.redirect) {
      history.replace(route.redirect)
      return
    }

    const renderReactApp = ReactDOM.render
    appInstance = renderReactApp(
      <HistoryContext.Provider value={history}>
        <StyleContext.Provider value={{ insertCss }}>
          <ReduxProvider store={store}>
            <App context={context}>{route.component}</App>
          </ReduxProvider>
        </StyleContext.Provider>
      </HistoryContext.Provider>,
      container,
      () => {
        document.title = route.title

        updateMeta('description', route.description)
        // Update necessary tags in <head> at runtime here, ie:
        // updateMeta('keywords', route.keywords);
        // updateCustomMeta('og:url', route.canonicalUrl);
        // updateCustomMeta('og:image', route.imageUrl);
        // updateLink('canonical', route.canonicalUrl);
        // etc.

        if (isInitialRender) {
          const elem = document.getElementById('css')
          if (elem) elem.parentNode.removeChild(elem)
          return
        }

        let scrollX = 0
        let scrollY = 0
        const pos = scrollPositionsHistory[location.key]
        if (pos) {
          scrollX = pos.scrollX
          scrollY = pos.scrollY
        } else {
          const targetHash = location.hash.substr(1)
          if (targetHash) {
            const target = document.getElementById(targetHash)
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top
            }
          }
        }

        // Restore the scroll position if it was saved into the state
        // or scroll to the given #hash anchor
        // or scroll to top of the page
        window.scrollTo(scrollX, scrollY)

        // Google Analytics tracking. Don't send 'pageview' event after
        // the initial rendering, as it was already sent
        if (window.ga) {
          window.ga('send', 'pageview', createPath(location))
        }
      },
    )
  } catch (error) {
    if (__DEV__) {
      throw error
    }

    Logger.error(error)

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      // Reserve a short period of time to send error log
      setTimeout(window.location.reload, 500)
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange)
onLocationChange(currentLocation)

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance)
    }

    onLocationChange(currentLocation)
  })
}
