import BaseApi from 'actions/api/BaseApi'

const api = new BaseApi().create()
const report = new BaseApi().export()
const template = new BaseApi().create({ responseType: 'text' })
const file = new BaseApi().getFile()

const login = (data, countryCode) =>
  api({
    url: '/opi/v1/organizer/login',
    method: 'post',
    data,
    loadingTarget: 'system',
    headers: {
      ...(countryCode ? { 'X-Bookyay-Cc': countryCode } : {}),
    },
  })

const validate = (token, countryCode) =>
  api({
    url: '/opi/v1/organizer/validate',
    method: 'post',
    headers: {
      Authorization: `bearer ${token}`,
      ...(countryCode ? { 'X-Bookyay-Cc': countryCode } : {}),
    },
  })

const organizerList = params =>
  api({
    url: '/opi/v1/organizers',
    method: 'get',
    params,
  })

const organizerGet = id =>
  api({
    url: `/opi/v1/organizers/${id}`,
    method: 'get',
  })

const organizerCreate = data =>
  api({
    url: '/opi/v1/organizers',
    method: 'post',
    data,
    loadingTarget: 'system',
  })

const organizerUpdate = (id, data, token = '') =>
  api({
    url: `/opi/v1/organizers/${id}`,
    method: 'put',
    data,
    loadingTarget: 'system',
    ...(token
      ? {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      : {}),
  })

const organizerDelete = id =>
  api({
    url: `/opi/v1/organizers/${id}`,
    method: 'delete',
    loadingTarget: 'system',
  })

const organizerRegister = data =>
  api({
    url: '/opi/v1/organizers/register',
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const otpCreate = data =>
  api({
    url: '/opi/v1/otp',
    method: 'post',
    data,
    loadingTarget: 'system',
  })

const organizerStatusUpdate = (id, data) =>
  api({
    url: `/opi/v1/organizers/${id}/state`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const organizerIdentityUpdate = data =>
  api({
    url: '/opi/v1/organizer/updateidentity',
    method: 'post',
    data,
    loadingTarget: 'system',
  })

const organizerIdentityUpdateConfirm = data =>
  api({
    url: '/opi/v1/organizer/updateidentity/confirm',
    method: 'post',
    data,
    loadingTarget: 'system',
  })

const organizerRevisions = params =>
  api({
    url: `/opi/v1/organizers/revisions`,
    method: 'get',
    params,
  })

const organizerUpdatePassword = data =>
  api({
    url: `/opi/v1/organizer/pwd`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const organizerResetPassword = data =>
  api({
    url: `/opi/v1/organizer/resetpwd`,
    method: 'post',
    data,
    loadingTarget: 'system',
  })

const organizerStaff = params =>
  api({
    url: `/opi/v1/organizers/staff`,
    method: 'get',
    params,
  })

const organizerBlock = (id, data) =>
  api({
    url: `/opi/v1/organizers/${id}/block`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const organizerReportExport = params =>
  report({
    url: '/opi/v1/organizers/report',
    method: 'get',
    params,
    loadingTarget: 'system',
  })

const organizerStores = params =>
  api({
    url: '/opi/v1/organizers/stores',
    method: 'get',
    params,
  })

const organizerStoreCreate = data =>
  api({
    url: '/opi/v1/organizers/stores',
    method: 'post',
    data,
    loadingTarget: 'system',
  })

const organizerStoreUpdate = (id, data) =>
  api({
    url: `/opi/v1/organizers/stores/${id}`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const organizerStoreDelete = id =>
  api({
    url: `/opi/v1/organizers/stores/${id}`,
    method: 'delete',
    loadingTarget: 'system',
  })

const organizerBankInfoUpdate = (id, data) =>
  api({
    url: `/opi/v1/organizers/${id}/bank`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const organizerBanks = params =>
  api({
    url: `/opi/v1/organizers/banks`,
    method: 'get',
    params,
  })

const organizerBankCreate = data =>
  api({
    url: '/opi/v1/organizers/banks',
    method: 'post',
    data,
    loadingTarget: 'system',
  })

const organizerBankUpdate = (id, data) =>
  api({
    url: `/opi/v1/organizers/banks/${id}`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const organizerBanksExport = params =>
  report({
    url: '/opi/v1/organizers/banks/report',
    method: 'get',
    params,
    loadingTarget: 'system',
  })

const organizerAutosave = (data, loadingTarget) =>
  api({
    url: `/opi/v1/organizers/autosave`,
    method: 'post',
    data,
    loadingTarget,
  })

const organizersDocIdentity = params =>
  file({
    url: `/opi/v1/organizers/doc/identity`,
    method: 'get',
    params,
    loadingTarget: 'system',
  })

const organizersDocIdentityUpload = data =>
  api({
    url: `/opi/v1/organizers/doc/identity`,
    method: 'post',
    data,
    loadingTarget: 'system',
  })

const organizersBankAuthLetter = params =>
  file({
    url: `/opi/v1/organizers/banks/authLetter`,
    method: 'get',
    params,
    loadingTarget: 'system',
  })

const organizersBankAuthLetterUpload = data =>
  api({
    url: `/opi/v1/organizers/banks/authLetter`,
    method: 'post',
    data,
    loadingTarget: 'system',
  })

const organizerTnc = ({ fileName, ...params }) =>
  report({
    url: '/opi/v1/organizers/tnc',
    method: 'get',
    params,
    loadingTarget: 'system',
    defaultFilename: fileName || 'T&C.pdf',
  })

const organizerTncTemplate = ({ token, ...params }) =>
  template({
    url: '/opi/v1/organizers/tnc/template',
    method: 'get',
    params,
    ...(token
      ? {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      : {}),

    // loadingTarget: 'system',
  })

const organizerTncAgree = (data, token) =>
  api({
    url: `/opi/v1/organizers/tnc/agree`,
    method: 'post',
    data,
    loadingTarget: 'system',
    ...(token
      ? {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      : {}),
  })

const organizerConcertTnc = params =>
  file({
    url: '/opi/v1/organizers/tnc',
    method: 'get',
    params,
    loadingTarget: 'system',
  })

const organizerConcertApplicationsList = params =>
  api({
    url: '/opi/v1/organizers/concertApplications',
    method: 'get',
    params,
  })

const organizerConcertApplicationsCreate = data =>
  api({
    url: '/opi/v1/organizer/concertApplications',
    method: 'post',
    data,
    loadingTarget: 'system',
  })

const organizerConcertApplicationStatusUpdate = (id, data) =>
  api({
    url: `/opi/v1/organizers/concertApplications/${id}/state`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const organizerConcertApplicationsExport = params =>
  report({
    url: '/opi/v1/organizers/concertApplications/report',
    method: 'get',
    params,
    loadingTarget: 'system',
  })

const organizerCountryCodes = params =>
  api({
    url: '/opi/v1/organizer/countryCodes',
    method: 'get',
    params,
  })

const organizerApi = {
  login,
  validate,
  organizerList,
  organizerGet,
  organizerCreate,
  organizerUpdate,
  organizerDelete,
  organizerRegister,
  otpCreate,
  organizerStatusUpdate,
  organizerIdentityUpdate,
  organizerIdentityUpdateConfirm,
  organizerRevisions,
  organizerUpdatePassword,
  organizerResetPassword,
  organizerStaff,
  organizerBlock,
  organizerReportExport,
  organizerStores,
  organizerStoreCreate,
  organizerStoreUpdate,
  organizerStoreDelete,
  organizerBankInfoUpdate,
  organizerBanks,
  organizerBankCreate,
  organizerBankUpdate,
  organizerBanksExport,
  organizerAutosave,
  organizersDocIdentity,
  organizersDocIdentityUpload,
  organizersBankAuthLetter,
  organizersBankAuthLetterUpload,
  organizerTnc,
  organizerConcertTnc,
  organizerTncTemplate,
  organizerTncAgree,
  organizerConcertApplicationsCreate,
  organizerConcertApplicationsList,
  organizerConcertApplicationStatusUpdate,
  organizerConcertApplicationsExport,
  organizerCountryCodes,
}

export default organizerApi
