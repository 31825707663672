import { createAction } from 'redux-actions'

// ------------------------------------
// Constants
// ------------------------------------
export const SET_TNC_MODAL = '@SET_TNC_MODAL'
export const DISMISS_TNC_MODAL = '@DISMISS_TNC_MODAL'

// ------------------------------------
// Actions
// ------------------------------------
export const setTncModal = createAction(SET_TNC_MODAL)

export const dismissTncModal = createAction(DISMISS_TNC_MODAL)
