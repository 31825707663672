import { t } from 'acs-translate'
import Logger from 'utils/Logger'

import { enqueueSnackbar } from './notifications'
import { logout } from './organizer'

const defaultResultTransform = data => data

const createGetAction = ({
  // name,
  action,
  // variables = {},
  onComplete,
  onError,
  transform = defaultResultTransform,
  defaultValue = {},
  useCustomErrorMessage = false,
  defaultErrorMessageCd = null,
  shouldThrowError = false,
  // it will replace all params values
  // format: {code: 'xxxx', others params}
  specialHandlingErrors = [],
  // lazy = false,
}) => async dispatch => {
  try {
    const rslt = await dispatch(action)

    // eslint-disable-next-line no-unused-expressions
    onComplete?.(transform(rslt))
    return transform(rslt)
  } catch (err) {
    const specialHandlingError = specialHandlingErrors.find(
      setting => setting.code === err.code,
    )
    const isSpecialHandlingError = !!specialHandlingError

    const final = {
      onError: isSpecialHandlingError ? specialHandlingError.onError : onError,
      useCustomErrorMessage: isSpecialHandlingError
        ? specialHandlingError.useCustomErrorMessage
        : useCustomErrorMessage,
      defaultErrorMessageCd: isSpecialHandlingError
        ? specialHandlingError.defaultErrorMessageCd
        : defaultErrorMessageCd,
      shouldThrowError: isSpecialHandlingError
        ? specialHandlingError.shouldThrowError
        : shouldThrowError,
    }

    // eslint-disable-next-line no-unused-expressions
    final.onError?.(err)
    Logger.error(err)

    const isUnauth =
      err?.response?.status === 401 ||
      err?.errStatus === 401 ||
      ['8626', '8330', '8206'].indexOf(err?.code) > -1

    if (isUnauth) {
      const isExpired = (err?.response?.status || err?.errStatus) === 401
      dispatch(
        enqueueSnackbar({
          preventDuplicate: true,
          message: t(isExpired ? 'errYyBoLoginExpired' : `errYyBo${err.code}`),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      )
      dispatch(logout())
    }

    if (!isUnauth && final.defaultErrorMessageCd) {
      let jsonErr = err

      // handle export's error
      if (err instanceof Blob) {
        const errorText = await err.text()
        jsonErr = JSON.parse(errorText)
      }

      const errorCd = jsonErr?.code || jsonErr?.response?.status

      const getErrorMessage = () => {
        if (!errorCd) return t(final.defaultErrorMessageCd)

        return `${t(`${final.defaultErrorMessageCd}${errorCd}`, {
          fallback: t('errSystemError'),
        })}(${errorCd})`
      }

      dispatch(
        enqueueSnackbar({
          message: final.useCustomErrorMessage
            ? getErrorMessage()
            : t('errSystemError'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      )
    }

    if (final.shouldThrowError) {
      throw err
    }
  }

  return defaultValue
}

export default createGetAction
