import BaseApi from 'actions/api/BaseApi'

const api = new BaseApi().create()
const report = new BaseApi().export()

const userTicketReportList = params =>
  api({
    url: '/opi/v1/users/tickets',
    method: 'get',
    params,
  })

const userTicketStateUpdate = (id, data) =>
  api({
    url: `/opi/v1/users/tickets/${id}/state`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const userTicketExtrainfoUpdate = (id, data) =>
  api({
    url: `/opi/v1/users/tickets/${id}/extrainfo`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const userTicketReportExport = params =>
  report({
    url: '/opi/v1/users/tickets/report',
    method: 'get',
    params,
    loadingTarget: 'system',
  })

const userTicketChangeSession = data =>
  api({
    url: '/opi/v1/users/tickets/changeSession',
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const userVoucherReportList = params =>
  api({
    url: '/opi/v1/users/vouchers',
    method: 'get',
    params,
  })

const userVoucherStateUpdate = (id, data) =>
  api({
    url: `/opi/v1/users/vouchers/${id}/state`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const userVoucherReportExport = params =>
  report({
    url: '/opi/v1/users/vouchers/report',
    method: 'get',
    params,
    loadingTarget: 'system',
  })

const userTicketPdfExport = data =>
  api({
    url: '/opi/v1/users/tickets/pdf',
    method: 'post',
    data,
  })

const userNftReportList = params =>
  api({
    url: '/opi/v1/users/nfts',
    method: 'get',
    params,
  })

const userNftReportExport = params =>
  report({
    url: '/opi/v1/users/nfts/report',
    method: 'get',
    params,
    loadingTarget: 'system',
  })

const userNftBlockchainList = params =>
  api({
    url: '/opi/v1/users/nfts/blockchain',
    method: 'get',
    params,
  })

const userAddrsCountries = params =>
  api({
    url: '/opi/v1/users/addrs/countries',
    method: 'get',
    params,
    loadingTarget: 'useraddrs.countries',
  })

const userTicketTransfer = (id, data) =>
  api({
    url: `/opi/v1/users/tickets/${id}/transfer`,
    method: 'put',
    data,
    loadingTarget: 'system',
  })

const userTicketTransferHistory = params =>
  api({
    url: '/opi/v1/users/tickets/transferHistory',
    method: 'get',
    params,
  })

const userTicketTransferHistoryReport = params =>
  report({
    url: '/opi/v1/users/tickets/transferHistory/report',
    method: 'get',
    params,
    loadingTarget: 'system',
  })

const userApi = {
  userTicketReportList,
  userTicketStateUpdate,
  userTicketExtrainfoUpdate,
  userTicketReportExport,
  userTicketChangeSession,
  userVoucherReportList,
  userVoucherStateUpdate,
  userVoucherReportExport,
  userTicketPdfExport,
  userNftReportList,
  userNftReportExport,
  userNftBlockchainList,
  userAddrsCountries,
  userTicketTransfer,
  userTicketTransferHistory,
  userTicketTransferHistoryReport,
}

export default userApi
