export const ERR_DEFAULT = '999'
export const ERR_RESERVE_ADJACENT_SEAT_NOT_FULFILLED = '8551'
export const ERR_CHECKOUT_ITEMS_PRICE_UPDATED = '9004'
export const ERR_RESERVE_SEAT_IN_ORDER_ALREADY = '9005'
export const ERR_LOOKAY_DUPLICATE_SESSION = '10003'
export const ERR_INVALID_HELD_TICKET_EXISTS = '9313'
export const ERR_LOGIN_INCORRECT_PWD = '8201'
export const ERR_IMPORT_CANCEL_ORDERTEMPLATE_FAIL = '8921'
export const ERR_IMPORT_CSV_ERROR = '9400'
export const ERR_INVALID_UPDATE_EVENT_TEMPLATE_FOR_INCLUDED_BY_PACKAGE_ITEMS =
  '8513'
export const ERR_INVALID_PACKAGE_SUBMIT_FOR_NO_PRODUCT_ITEM_INCLUDED = '9532'
export const ERR_INVALID_PACKAGE_SUBMIT_FOR_NO_EVENT_ITEM_INCLUDED = '9533'
export const ERR_INVALID_OFF_SHELVE_FOR_INCLUDED_BY_PACKAGE_ITEMS = '8333'
