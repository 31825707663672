import {
  DISMISS_CHANGE_LOCATION_DIALOG,
  SET_CHANGE_LOCATION_DIALOG,
  UPDATE_LOCATION,
} from 'actions/location'
import { handleActions } from 'redux-actions'

const initialState = { isOpen: false, countryCode: 'HK', currencyCode: 'HKD' }

export default handleActions(
  {
    [SET_CHANGE_LOCATION_DIALOG]: (state, { payload }) => ({
      ...state,
      ...payload,
      isOpen: true,
    }),
    [DISMISS_CHANGE_LOCATION_DIALOG]: ({ countryCode, currencyCode }) => ({
      countryCode,
      currencyCode,
      isOpen: false,
    }),
    [UPDATE_LOCATION]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
)
