exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._5NYOx{color:#e81e62;font-weight:400;font-size:20px;margin:24px 0}._5NYOx._2qSCJ{color:rgba(0,0,0,.87)}.ox_rv{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;min-width:512px;max-width:100vw;white-space:pre-line;text-align:center}._1jJat{min-width:120px;-ms-flex:1 1 0%;flex:1 1 0%;margin:0 8px}._3woeA{margin:24px 0;padding:0 24px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._1Wf6u{color:#d00;font-size:60px}", ""]);

// exports
exports.locals = {
	"title": "_5NYOx",
	"normal": "_2qSCJ",
	"content": "ox_rv",
	"button": "_1jJat",
	"actions": "_3woeA",
	"icon": "_1Wf6u"
};