import { CLEAR_INIT_INFO, SET_INIT_INFO } from 'actions/init'
import { handleActions } from 'redux-actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default handleActions(
  {
    [SET_INIT_INFO]: (_, { payload }) => payload,
    [CLEAR_INIT_INFO]: () => initialState,
  },
  initialState,
)
