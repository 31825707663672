import { createAction } from 'redux-actions'

// ------------------------------------
// Constants
// ------------------------------------
export const SET_VERIFICATION_CODE_DIALOG = '@SET_VERIFICATION_CODE_DIALOG'
export const DISMISS_VERIFICATION_CODE_DIALOG =
  '@DISMISS_VERIFICATION_CODE_DIALOG'
// ------------------------------------
// Actions
// ------------------------------------
export const setVerificationCodeDialog = createAction(
  SET_VERIFICATION_CODE_DIALOG,
)

export const dismissVerificationCodeDialog = createAction(
  DISMISS_VERIFICATION_CODE_DIALOG,
)
