import locale from 'acs-translate/reducer'

import config from './config'
import dialog from './dialog'
import init from './init'
import loading from './loading'
import location from './location'
import modals from './modal'
import notifications from './notifications'
import system from './system'
import tncModal from './tncModal'
import user from './user'
import verificationCode from './verificationCode'
import otpSecret from './otpSecret'

export default {
  init,
  user,
  dialog,
  locale,
  loading,
  modals,
  system,
  notifications,
  location,
  config,
  tncModal,
  verificationCode,
  otpSecret,
}
