import { createAction } from 'redux-actions'

import configApi from './api/config'
import createFetchAction from './actionCreator'

const { systemConfigGet } = configApi

export const SET_SYSTEM_CONFIG = 'SET_SYSTEM_CONFIG'

export const setSystemConfig = createAction(SET_SYSTEM_CONFIG)

export const getSystemConfig = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: systemConfigGet(data),
      onComplete: rslt => dispatch(setSystemConfig(rslt)),
    }),
  )

export default { getSystemConfig }
