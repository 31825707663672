import { createAction } from 'redux-actions'

import createFetchAction from './actionCreator'
import userApi from './api/users'

const { userAddrsCountries } = userApi

// ------------------------------------
// Constants
// ------------------------------------
export const SET_CHANGE_LOCATION_DIALOG = '@SET_CHANGE_LOCATION_DIALOG'
export const DISMISS_CHANGE_LOCATION_DIALOG = '@DISMISS_CHANGE_LOCATION_DIALOG'
export const UPDATE_LOCATION = '@UPDATE_LOCATION'
// ------------------------------------
// Actions
// ------------------------------------
export const setChangeLocationDialog = createAction(SET_CHANGE_LOCATION_DIALOG)

export const dismissChangeLocationDialog = createAction(
  DISMISS_CHANGE_LOCATION_DIALOG,
)

export const updateLocation = createAction(UPDATE_LOCATION)

export const getLocationList = data => async dispatch =>
  dispatch(
    createFetchAction({
      action: userAddrsCountries({ type: 'location', ...data }),
    }),
  )
