import { SET_LOADING_DONE, SET_LOADING_PROGRESS } from 'actions/loading'
import {
  SET_LOCALE_FAILURE,
  SET_LOCALE_REQUEST,
  SET_LOCALE_SUCCESS,
} from 'acs-translate/action'
/**
 * Locale switcher
 */
import { handleActions } from 'redux-actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = false

export default handleActions(
  {
    [SET_LOADING_PROGRESS]: () => true,
    [SET_LOADING_DONE]: () => false,

    [SET_LOCALE_REQUEST]: () => true,
    [SET_LOCALE_SUCCESS]: () => false,
    [SET_LOCALE_FAILURE]: () => false,
  },
  initialState,
)
