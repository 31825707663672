import {
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from 'actions/notifications'
import { handleActions } from 'redux-actions'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = []

export default handleActions(
  {
    [ENQUEUE_SNACKBAR]: (state, { payload }) => [...state, payload],
    [CLOSE_SNACKBAR]: (state, { payload }) =>
      state.map(notification =>
        !payload || notification.key === payload
          ? { ...notification, dismissed: true }
          : { ...notification },
      ),
    [REMOVE_SNACKBAR]: (state, { payload }) =>
      state.filter(n => n.key !== payload),
  },
  initialState,
)
