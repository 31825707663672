export const LOCATION_MAP = {
  HK: {
    value: 'HK',
    label: 'txtYyBoHongKong',
    currencyCode: 'HKD',
  },
  MO: {
    value: 'MO',
    label: 'txtYyBoMacau',
    currencyCode: 'MOP',
  },
  TW: {
    value: 'TW',
    label: 'txtYyBoTaiwan',
    currencyCode: 'NTD',
  },
  CN: {
    value: 'CN',
    label: 'txtYyBoChina',
    currencyCode: 'CNY',
  },
  SG: {
    value: 'SG',
    label: 'txtYyBoSingapore',
    currencyCode: 'SGD',
  },
  MY: {
    value: 'MY',
    label: 'txtYyBoMalaysia',
    currencyCode: 'MYR',
  },
}

const ACTIVE_LIST = ['HK', 'TW', 'MO']

export const LOCATION_OPTIONS = Object.keys(LOCATION_MAP)
  .filter(key => ACTIVE_LIST.includes(key))
  .map(key => LOCATION_MAP[key])

export const CURRENCY_OPTIONS = Object.keys(LOCATION_MAP)
  .filter(key => ACTIVE_LIST.includes(key))
  .map(key => {
    const { currencyCode } = LOCATION_MAP[key]
    return { value: currencyCode, label: currencyCode }
  })

export default LOCATION_OPTIONS
