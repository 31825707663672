import React, { memo } from 'react'
import { t, useLocale } from 'acs-translate'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useStyles from 'isomorphic-style-loader/useStyles'

import s from './ErrorDialog.scss'
import useCloseCallback from './hooks/useCloseCallback'

const ErrorDialog = memo(({ id, title, message, confirmLabel, callback }) => {
  useLocale()
  useStyles(s)
  const onRequestClose = useCloseCallback(id, callback)

  const handleConfirm = () => {
    onRequestClose()
    if (callback) callback()
  }
  return (
    <Dialog open onClose={onRequestClose}>
      <Box mt={2} display="flex" alignItems="center" justifyContent="center">
        <ErrorOutlineOutlinedIcon className={s.icon} />
      </Box>
      {!!title && (
        <DialogContent className={cx(s.content, s.title)}>
          {t(title, { fallback: title })}
        </DialogContent>
      )}
      {!!message && (
        <DialogContent className={s.content}>
          <DialogContentText id="alert-dialog-description">
            {t(message, { fallback: message })}
          </DialogContentText>
        </DialogContent>
      )}
      <div className={s.actions}>
        <Button
          className={s.button}
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disableElevation
          autoFocus>
          {t(confirmLabel, { fallback: confirmLabel })}
        </Button>
      </div>
    </Dialog>
  )
})

ErrorDialog.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  callback: PropTypes.func,
  confirmLabel: PropTypes.string,
}

ErrorDialog.defaultProps = {
  callback: null,
  title: '',
  confirmLabel: 'txtYyBoConfirm',
}

export default ErrorDialog
