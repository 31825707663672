import { applyMiddleware, compose, createStore } from 'redux'
// import ClutchClientApi from 'clutch-client-api/middlewares'
import RavenMiddleware from 'redux-raven-middleware'
import thunk from 'redux-thunk'

import { makeRootReducer } from './reducers'
import createHelpers from './createHelpers'

export default function configureStore(initialState, helpersConfig) {
  const helpers = createHelpers(helpersConfig)
  const middleware = [
    thunk.withExtraArgument(helpers),
    // ClutchClientApi.createMiddleware(),
  ]

  if (__SENTRY__) {
    middleware.push(RavenMiddleware())
  }

  let enhancer

  if (__DEV__) {
    // eslint-disable-next-line global-require
    const createLogger = require('./logger').default
    middleware.push(createLogger())

    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
    let devToolsExtension = f => f
    if (process.env.BROWSER && window.__REDUX_DEVTOOLS_EXTENSION__) {
      devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__()
    }

    enhancer = compose(
      applyMiddleware(...middleware),
      devToolsExtension,
    )
  } else {
    enhancer = applyMiddleware(...middleware)
  }

  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(makeRootReducer(), initialState, enhancer)
  store.asyncReducers = {}

  // const wsUriList = ['ws://m.acsdev.net:9191/helios']
  // ClutchClientApi.setup({
  //   store,
  //   wsUriList:
  //     window.location.protocol === 'https:'
  //       ? wsUriList.filter(wsUri => wsUri.indexOf('wss://') > -1)
  //       : wsUriList,
  //   // settings: clutch,
  //   debug: true,
  // })

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept('../reducers', () => {
      // eslint-disable-next-line global-require
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}
