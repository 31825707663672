import { createAction } from 'redux-actions'
import Modal from 'components/Modal/Modal'
import random from 'utils/random'

// ------------------------------------
// Constants
// ------------------------------------
const SET_SUCCESS_MESSAGE = '@SET_SUCCESS_MESSAGE'
const SET_SYSTEM_ERROR = '@SET_SYSTEM_ERROR'
const DISMISS_MODAL = '@DISMISS_SYSTEM_MODAL'

export const SET_CONFIRM_MESSAGE = '@SET_CONFIRM_MESSAGE'
// ------------------------------------
// Actions
// ------------------------------------
export const setSuccessMessage = createAction(SET_SUCCESS_MESSAGE, payload => ({
  ...payload,
  type: Modal.types.success,
  id: random(),
}))

export const setSystemError = createAction(SET_SYSTEM_ERROR, payload => ({
  ...payload,
  type: Modal.types.error,
  id: random(),
}))

export const setConfirmMessage = createAction(SET_CONFIRM_MESSAGE, payload => ({
  ...payload,
  type: Modal.types.confirm,
  id: random(),
}))

export const dismissSystemModal = createAction(DISMISS_MODAL)
