import {
  DISMISS_CONFIRM_DIALOG,
  SET_CONFIRM_DIALOG,
  SET_SUCCESS_DIALOG,
} from 'actions/dialog'
import { handleActions } from 'redux-actions'

const initialState = { isOpen: false }

export default handleActions(
  {
    [SET_CONFIRM_DIALOG]: (state, { payload }) => ({
      ...payload,
      title: payload?.title || '',
      message: payload?.message || '',
      isOpen: true,
      type: 'confirm',
    }),
    [SET_SUCCESS_DIALOG]: (state, { payload }) => ({
      ...payload,
      title: payload?.title || '',
      message: payload?.message || '',
      isOpen: true,
      type: 'success',
    }),
    [DISMISS_CONFIRM_DIALOG]: () => initialState,
  },
  initialState,
)
